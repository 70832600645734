<template>
  <block
    component-name="block-launcher-btn-list"
    :px="false"
    data-skin="sand"
    body-class="flex flex-col gap-3xl pt-4xl bg-skin-subtle"
  >
    <header class="flex flex-col w-full text-skin-base px-edge">
      <wrapper-reveal reveal="fade-from-right">
        <div class="text-overline" v-html="$t.labels.contacts"></div>
      </wrapper-reveal>

      <wrapper-split-text reveal="words">
        <div class="text-display-hero" v-html="$t.labels.get_in_touch"></div>
      </wrapper-split-text>
    </header>

    <div class="flex flex-col w-full">
      <div class="flex flex-col items-stretch w-full pt-px -mt-px">
        <!-- STORE LOCATOR -->
        <div v-if="storeLocatorEnabled" class="contents">
          <divider />

          <wrapper-reveal class="w-full" reveal="fade-from-0" delay="250">
            <btn-solid
              size="huge"
              :title="$t.labels.find_showroom_reseller"
              :action="{ to: '/store-locator' }"
              icon-right="store-locator"
              class="w-full max-w-full"
            />
          </wrapper-reveal>
        </div>
        <!-- end STORE LOCATOR -->

        <!-- SIGN UP AS PROFESSIONAL -->
        <div v-if="!userStore.logged" class="contents">
          <divider />

          <wrapper-reveal class="w-full" reveal="fade-from-0">
            <btn-solid
              size="huge"
              :title="$t.labels.signup_as_professional"
              :action="{ to: '/signup/professional' }"
              :use-tracking="{
                event: 'click_form_signup_professional'
              }"
              icon-right="user-pro"
              class="w-full max-w-full"
            />
          </wrapper-reveal>
        </div>
        <!-- end SIGN UP AS PROFESSIONAL -->

        <divider />

        <!-- SUPPORT -->
        <wrapper-reveal class="w-full" reveal="fade-from-0">
          <btn-solid
            size="huge"
            :title="$t.labels.info_request"
            :action="{ to: '/support' }"
            :use-tracking="{
              event: 'click_form_request_information'
            }"
            icon-right="info"
            class="w-full max-w-full"
          />
        </wrapper-reveal>
        <!-- end SUPPORT -->

        <divider />

        <!-- NEWSLETTER -->
        <wrapper-reveal class="w-full" reveal="fade-from-0">
          <collapse
            :title="$t.labels.subscribe_to_newsletter"
            class="w-full max-w-full"
            @click="onNewsletterClick"
            ref="tpl_newsletter"
          >
            <template #header="collapseProps">
              <btn-solid
                size="huge"
                button-tag="summary"
                :title="collapseProps.title"
                :use-tracking="{
                  event: 'click_form_signup_newsletter'
                }"
                icon-right="mail"
                class="w-full max-w-full"
              />
            </template>

            <div
              class="flex flex-col laptop:flex-row gap-x-ui gap-y-2xl p-edge"
            >
              <section class="w-full laptop:w-1/2">
                <wrapper-split-text
                  reveal="chars"
                  class="left-0 laptop:sticky laptop:top-40"
                >
                  <div
                    class="text-heading-6"
                    v-html="$t.texts.newslettter"
                  ></div>
                </wrapper-split-text>
              </section>

              <section class="w-full laptop:w-1/2">
                <form-newsletter class="w-full" />
              </section>
            </div>
          </collapse>
        </wrapper-reveal>
        <!-- end NEWSLETTER -->
      </div>
    </div>
  </block>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
const userStore = useUserStore();
const { storeLocatorEnabled } = useGlobals();
const nuxtApp = useNuxtApp();

const tpl_newsletter = templateRef("tpl_newsletter");

function onNewsletterClick() {
  /* gsap.to(window, {
    duration: 0.3,
    scrollTo: { y: tpl_newsletter.value.$el },
  }); */
  nuxtApp.$lenis.scrollTo(tpl_newsletter.value.$el, {
    force: true,
    lock: true,
    duration: 0.3,
    easing: gsap.parseEase("power2.out"),
    onComplete: () => {
      ScrollTrigger.refresh(true);
    },
  });
}
</script>
