<template>
  <input-form
    component-name="form-newsletter"
    ref="tpl_form"
    :use-mandatory-fields-warning="true"
    :use-privacy="true"
    :success-message="$t.texts.newsletter_success"
    :submit-callback="handleSubmit"
    :success-callback="handleSuccess"
    :value="initialValue"
  >
    <input-language />
    <input-first-name class="w-full" />
    <input-last-name class="w-full" />
    <input-email :use-confirm="false" class="w-full" />
    <input-professional-title :use-company="false" class="w-full" />
    <input-country class="w-full" />
  </input-form>
</template>

<script setup>
const initialValue = ref({
  email: "",
});

const tpl_form = templateRef("tpl_form");
const nuxtApp = useNuxtApp();
function handleSuccess(data) {}

async function handleSubmit(fields) {
  const data = {
    ...fields.email_group,
    ...Object.keys(fields)
      .filter((key) => key !== "email_group")
      .reduce((obj, key) => {
        obj[key] = fields[key];
        return obj;
      }, {}),
  };

  data.country = data.country_code;
  delete data.country_code;

  data.profession = data.title;
  delete data.title;

  return $fetch(`/api/newsletter?lng=${data.language}`, {
    method: "POST",
    body: data,
  }).then((data) => {
    if (!data) {
      tpl_form.value.handleErrors([nuxtApp.$t.labels.something_went_wrong]);
    }

    const { status, error } = data;

    console.log("status", status, "error", error);

    if (error == "Member Exists") {
      tpl_form.value.handleErrors([nuxtApp.$t.texts.email_already_subscribed]);
    } else if (status !== "success" || error) {
      tpl_form.value.handleErrors([nuxtApp.$t.labels.something_went_wrong]);
    }

    return data;
  });
}
</script>
